import { axiosRectangleInstance, handleResponse, handleError } from './index';

export async function cancelPaymentLink(transactionId) {
  try {
    const response = await axiosRectangleInstance.post('/payment-link/cancel', {
      transactionId,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createDraftTransaction(reqBody) {
  try {
    const response = await axiosRectangleInstance.post('/draft', reqBody);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createPaymentLink(reqBody) {
  try {
    const response = await axiosRectangleInstance.post(
      '/payment-link',
      reqBody
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function makePayment(reqBody) {
  try {
    const response = await axiosRectangleInstance.post('/payment', reqBody);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function processRefund(transactId, refundAmount, refundReason) {
  try {
    const body = {
      transactionId: transactId,
      amount: parseFloat(refundAmount),
      reason: refundReason,
    };
    const response = await axiosRectangleInstance.post('/refund', body);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function resendPaymentLink(reqBody) {
  try {
    const response = await axiosRectangleInstance.post(
      '/payment-link/resend',
      reqBody
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function processVoid(transactId, voidReason) {
  try {
    const body = {
      transactionId: transactId,
      reason: voidReason,
    };
    const response = await axiosRectangleInstance.post('/void', body);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createWallet(name, token, customerId) {
  try {
    const body = {
      name: name,
      token: token,
      customerId: customerId,
    };
    const response = await axiosRectangleInstance.post('/wallet', body);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function updateWallet(name, token, customerId) {
  try {
    const body = {
      name: name,
      token: token,
    };
    const response = await axiosRectangleInstance.put(
      '/wallet/' + customerId,
      body
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function deactivateWallet(customerId) {
  try {
    const response = await axiosRectangleInstance.post(
      '/wallet/' + customerId + '/deactivate'
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}
