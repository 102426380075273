// Modules
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import Env from 'Env';

// API
import { createDraftTransaction, makePayment } from 'utils/api/rectangleApi';
import { postTransactionReceipt } from '../../../../utils/api/transactionPublicApi';

// Hooks
import useStepHistory from 'utils/hooks/useStepHistory';

// Components
import { Modal, Button } from 'react-bootstrap';
import PaymentInfoStep from './PaymentInfoStep';
import PaymentResponseStep from './PaymentResponseStep';

// Helpers
import { cents } from 'utils/helpers/currencyFormat';
import { capitalizeName, isFullName } from 'utils/helpers/names';

// Constants
import PaymentStates from 'constants/PaymentStates';

// CSS
import PaymentRectangle from '../PaymentRectangle/PaymentRectangle';

const FormSteps = Object.freeze({
  PAYMENT_INFO: 0,
  PAYMENT_FORM: 1,
  PAYMENT_RESPONSE: 2,
  PAYMENT_FAILED: 3,
});

export default function RectangleModal(props) {
  const { showModal, setShowModal, triggerUpdate } = props;

  const publicKey = useSelector((s) => s.loggedPractice.rectanglePublicKey);
  const [selectedPetOwner, setSelectedPetOwner] = useState(null);
  const [staffMember, setStaffMember] = useState('Choose staff member...');
  const [petOwnerName, setPetOwnerName] = useState('');

  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [isFormLoading, setFormLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [draftTransaction, setDraftTransaction] = useState({});
  const [{ currentStep }, { setStep }] = useStepHistory();
  const [currentPaymentState, setCurrentPaymentState] = useState('');
  const [lastFourDigits, setLastFourDigits] = useState('XXXX');
  const [showReceiptOptions, setShowReceiptOptions] = useState(true);
  const [draftTransactionId, setDraftTransactionId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fields used for validation
  const STAFF_MEMBER_DEFINITION = {
    field: 'staffMember',
    value: staffMember,
    validationMessage: 'Select a staff member',
  };
  const TOTAL_AMOUNT_DEFINITION = {
    field: 'totalAmount',
    value: totalAmount,
    validationMessage: 'Enter a non-zero amount',
  };
  const DESCRIPTION_DEFINITION = {
    field: 'description',
    value: description,
    validationMessage: 'Enter a description',
  };
  const PET_OWNER_NAME_DEFINITION = {
    field: 'petOwnerName',
    value: petOwnerName,
    validationMessage: "Enter a pet owner's full name",
  };
  const step1Validation = [
    STAFF_MEMBER_DEFINITION,
    TOTAL_AMOUNT_DEFINITION,
    DESCRIPTION_DEFINITION,
    PET_OWNER_NAME_DEFINITION,
  ];

  const validatePaymentInfo = (stepValidation) => {
    const validationResult = validateStep(stepValidation);

    setValidationErrors(validationResult);
    return Object.keys(validationResult).length === 0;
  };

  const getBaseTransactionDetails = () => {
    return {
      petOwnerName: capitalizeName(petOwnerName),
      invoiceId: description,
      amount: cents(totalAmount, { resultType: 'number' }),
      staffMemberId: staffMember,
    };
  };

  /**
   *
   * @param {boolean}  isTerminal
   */
  const submitPaymentInfo = async () => {
    const stepIsValid = validatePaymentInfo(step1Validation);

    if (stepIsValid) {
      const newStep = FormSteps.PAYMENT_FORM;
      setStep(newStep);

      if (newStep === FormSteps.PAYMENT_FORM) {
        setIsLoading(true);

        const draftTransactionDetails = getBaseTransactionDetails();
        const draft = await createDraftTransaction(draftTransactionDetails);

        setDraftTransactionId(draft.transactionId);
        setDraftTransaction(draft);
      }
    }
  };

  const handleTransactionCompleted = async (msg) => {
    setLastFourDigits(msg.last4);

    setCurrentPaymentState(PaymentStates.Successful);
    triggerUpdate();

    setStep(FormSteps.PAYMENT_RESPONSE);
    setIsLoading(false);
  };

  const handleTransactionFailed = async (msg) => {
    setIsLoading(true);

    setCurrentPaymentState(PaymentStates.Failed);
    setErrorMessage(msg.errorMessage);

    setStep(FormSteps.PAYMENT_RESPONSE);
    setIsLoading(false);

    if (Env.sentryEnabled) {
      // Log payment errors in Sentry
      Sentry.withScope(() => {
        Sentry.setContext('transactionRsp', msg);
        Sentry.captureMessage(`Payment Failed: ${msg.ResponseMsg}`, {
          level: 'info',
        });
      });
    }
  };

  const tryAgain = async () => {
    try {
      setIsLoading(true);

      setStep(FormSteps.PAYMENT_FORM);
    } catch (err) {
      toast.error('Encountered an error reloading the payment form');
    }
  };

  const backAction = async () => {
    setStep(FormSteps.PAYMENT_INFO);
    setIsLoading(false);
  };

  const completePaymentAction = async (token) => {
    const reqBody = {
      token,
      invoiceNumber: description,
      petOwnerName,
      amount: Number(totalAmount) * 100,
      transactionId: draftTransactionId,
    };

    const response = await makePayment(reqBody);

    if (response.status === 'succeeded') {
      handleTransactionCompleted(response);
    } else {
      handleTransactionFailed(response);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size='md'
      aria-labelledby={`pay-in-full-modal`}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {currentStep === FormSteps.PAYMENT_INFO && 'Payment Information'}
          {currentStep === FormSteps.PAYMENT_FORM && 'Pay by Card'}
          {currentStep === FormSteps.PAYMENT_RESPONSE &&
            currentPaymentState === PaymentStates.Failed &&
            `Payment Failed`}
          {currentStep === FormSteps.PAYMENT_RESPONSE &&
            currentPaymentState === PaymentStates.Successful &&
            `Payment Successful`}
          {currentStep === FormSteps.PAYMENT_RESPONSE &&
            currentPaymentState === PaymentStates.Completed &&
            `Payment Completed`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {currentStep === FormSteps.PAYMENT_INFO && !isLoading && (
          <PaymentInfoStep
            staffMember={staffMember}
            setStaffMember={setStaffMember}
            petOwnerName={petOwnerName}
            setPetOwnerName={setPetOwnerName}
            description={description}
            setDescription={setDescription}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            isFormLoading={isFormLoading}
            setFormLoading={setFormLoading}
            validationErrors={validationErrors}
            selectedPetOwner={selectedPetOwner}
            setSelectedPetOwner={setSelectedPetOwner}
            enablePhoneNumber={false}
          />
        )}

        {currentStep === FormSteps.PAYMENT_FORM && (
          <PaymentRectangle
            publicKey={publicKey}
            amount={Number(totalAmount) * 100}
            backAction={backAction}
            completePaymentAction={completePaymentAction}
          ></PaymentRectangle>
        )}

        {currentStep === FormSteps.PAYMENT_RESPONSE && (
          <PaymentResponseStep
            totalAmount={totalAmount}
            isFormLoading={isFormLoading}
            currentPaymentState={currentPaymentState}
            errorMessage={errorMessage}
            setCurrentPaymentState={setCurrentPaymentState}
            lastFourDigits={lastFourDigits}
            postTransactionReceipt={postTransactionReceipt}
            showReceiptOptions={showReceiptOptions}
            setShowReceiptOptions={setShowReceiptOptions}
            processedTransactionId={{ current: draftTransaction.transactionId }} // this is how the data structure is set up in PaymentResponseStep
            autoReceiptPhoneNumber={null}
          />
        )}

        {currentStep === FormSteps.PAYMENT_FAILED && (
          <div>
            <h2>Error</h2>
            <p>There was an error with the payment. Please contact support.</p>
            <p>VitusPay Transaction ID: {draftTransaction?.transactionId}</p>
          </div>
        )}
      </Modal.Body>

      {currentStep === FormSteps.PAYMENT_INFO && (
        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              setShowModal();
            }}
            disabled={false}
          >
            Cancel
          </Button>

          <Button
            variant='secondary'
            onClick={() => {
              submitPaymentInfo();
            }}
            disabled={isFormLoading}
          >
            Next
          </Button>
        </Modal.Footer>
      )}

      {currentStep === FormSteps.PAYMENT_RESPONSE &&
        currentPaymentState === PaymentStates.Failed && (
          <Modal.Footer>
            <Button
              variant='light'
              onClick={() => {
                tryAgain();
              }}
              disabled={isFormLoading}
            >
              Try again
            </Button>
          </Modal.Footer>
        )}

      {currentStep === FormSteps.PAYMENT_RESPONSE && !showReceiptOptions && (
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={setShowModal}
            disabled={isFormLoading}
          >
            Finished
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

function validateStep(rules) {
  const validationResult = rules.reduce((errors, vf) => {
    const error = !validateReducer(vf.field, vf.value);
    if (error) {
      errors[vf.field] = vf.validationMessage;
    }
    return errors;
  }, []);

  return validationResult;

  function validateReducer(field, value) {
    switch (field) {
      case 'totalAmount':
        return value > 0;
      case 'staffMember':
        return value?.length === 36;
      case 'petOwnerName':
        return value?.length > 0 && isFullName(value);
      case 'description':
        return value?.length > 0;
      default:
        return null;
    }
  }
}
