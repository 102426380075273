import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemsTable from 'components/Common/ItemsTable/ItemsTable';
import { dailyTransactionColumns } from './config/daily-transactions-config';
import Currency from 'components/Common/Currency/Currency';
import TransactionStatus from 'constants/TransactionStatus';
import PaymentDropdown from './PaymentDropdown';
import TextToPayModal from '../Payment/TextToPayModal';
import PayInFullModal from '../Payment/PayInFullModal';
import PaymentPlanModal from '../Payment/PaymentPlanModal';
import EmailToPayModal from '../Payment/EmailToPayModal';
import { Feature } from 'utils/helpers/featureFlag';
import PaymentPlatforms from 'constants/PaymentPlatforms';
import PayFabricModal from '../Payment/PayFabricModal';
import RectangleModal from '../Payment/RectangleModal';
import { TokenPayProvider } from 'utils/store/TokenPayContext';

export class DailyTransactionsList extends Component {
  state = {
    showTextToPayModal: false,
    showEmailToPayModal: false,
    showPayInFullModal: false,
    showPaymentPlanModal: false,
  };

  setShowTextToPayModal = () => {
    this.setState((state) => ({
      showTextToPayModal: !state.showTextToPayModal,
    }));
  };

  setShowEmailToPayModal = () => {
    this.setState((state) => ({
      showEmailToPayModal: !state.showEmailToPayModal,
    }));
  };

  setShowPayInFullModal = () => {
    this.setState((state) => ({
      showPayInFullModal: !state.showPayInFullModal,
    }));
  };

  setShowPaymentPlanModal = () => {
    this.setState((state) => ({
      showPaymentPlanModal: !state.showPaymentPlanModal,
    }));
  };

  generateSummary() {
    const { transactions } = this.props;

    const summaryData = transactions.reduce(
      (a, t) => {
        const tCountsAsProcessed = this.countsAsProcessed(t);
        a.pendingCount += t.isInProgress ? 1 : 0;
        a.processedCount += tCountsAsProcessed ? 1 : 0;
        a.fullProcessedCount +=
          tCountsAsProcessed && t.type.toLowerCase() === 'full' ? 1 : 0;
        a.monthlyProcessedCount +=
          tCountsAsProcessed && t.type.toLowerCase() === 'monthly payments'
            ? 1
            : 0;
        a.dollarsProcessed += tCountsAsProcessed ? t.net : 0;
        return a;
      },
      {
        pendingCount: 0,
        processedCount: 0,
        fullProcessedCount: 0,
        monthlyProcessedCount: 0,
        dollarsProcessed: 0,
      }
    );

    return [
      { title: 'Transactions In Progress', value: summaryData.pendingCount },
      {
        title: 'Total Transactions Processed',
        value: summaryData.processedCount,
      },
      {
        title: 'Full Transactions Processed',
        value: summaryData.fullProcessedCount,
      },
      {
        title: 'Monthly Payment Transactions Processed',
        value: summaryData.monthlyProcessedCount,
      },
      {
        title: 'Total Dollars Processed',
        value: (
          <Currency
            amount={summaryData.dollarsProcessed}
            defaultToZero={true}
          />
        ),
      },
    ];
  }

  countsAsProcessed(transaction) {
    return (
      !transaction.isInProgress &&
      transaction.status !== TransactionStatus.errorSendingLink &&
      transaction.status !== TransactionStatus.canceled &&
      transaction.status !== TransactionStatus.voided
    );
  }

  renderSummary() {
    return this.generateSummary().map(({ title, value = 0 }) => {
      return (
        <div
          key={title}
          className='p-3 text-center bg-white rounded shadow-sm d-flex flex-column flex-grow-1 flex-basis-10 min-width-200'
        >
          <h1>{value}</h1>
          <label>{title}</label>
        </div>
      );
    });
  }

  render() {
    return (
      <div className='pl-8 pr-8'>
        <TokenPayProvider
          enableTokenPay={this.props.paymentPlatform === 'rectangle'}
        >
          <PaymentDropdown
            setShowTextToPayModal={this.setShowTextToPayModal}
            setShowEmailToPayModal={this.setShowEmailToPayModal}
            setShowPayInFullModal={this.setShowPayInFullModal}
            setShowPaymentPlanModal={this.setShowPaymentPlanModal}
            payInFullEnabled={this.props.payInFullEnabled}
            paymentLinkEnabled={this.props.paymentLinkEnabled}
            onlyVetshipRxPayments={this.props.onlyVetshipRxPayments}
            installmentsEnabled={this.props.installmentsEnabled}
            practiceName={this.props.practiceName}
            practiceId={this.props.practiceId}
          />
          <TextToPayModal
            showModal={this.state.showTextToPayModal}
            setShowModal={this.setShowTextToPayModal}
            triggerUpdate={() => this.props.onRefresh()}
            installmentsEnabled={this.props.installmentsEnabled}
            installmentsMinAmount={this.props.installmentsMinAmount}
            practiceName={this.props.practiceName}
            practiceId={this.props.practiceId}
            paymentPlatform={this.props.paymentPlatform}
          />
          <Feature name='Email to Pay'>
            <EmailToPayModal
              showModal={this.state.showEmailToPayModal}
              setShowModal={this.setShowEmailToPayModal}
              triggerUpdate={() => this.props.onRefresh()}
              installmentsEnabled={this.props.installmentsEnabled}
              installmentsMinAmount={this.props.installmentsMinAmount}
              practiceName={this.props.practiceName}
              practiceId={this.props.practiceId}
              paymentPlatform={this.props.paymentPlatform}
            />
          </Feature>
          {this.state.showPayInFullModal &&
            this.props.paymentPlatform === PaymentPlatforms.spreedly && (
              <PayInFullModal
                practiceId={this.props.practiceId}
                showModal={this.state.showPayInFullModal}
                setShowModal={this.setShowPayInFullModal}
                triggerUpdate={() => this.props.onRefresh()}
                practiceName={this.props.practiceName}
              />
            )}
          {this.state.showPayInFullModal &&
            this.props.paymentPlatform === PaymentPlatforms.payfabric && (
              <PayFabricModal
                showModal={this.state.showPayInFullModal}
                setShowModal={this.setShowPayInFullModal}
                triggerUpdate={() => this.props.onRefresh()}
                payByTerminalEnabled={
                  !!this.props.payFabricDeviceIds.terminalDeviceId
                }
              />
            )}
          {this.state.showPayInFullModal &&
            this.props.paymentPlatform === PaymentPlatforms.rectangle && (
              <RectangleModal
                showModal={this.state.showPayInFullModal}
                setShowModal={this.setShowPayInFullModal}
                triggerUpdate={() => this.props.onRefresh()}
                payByTerminalEnabled={
                  !!this.props.payFabricDeviceIds.terminalDeviceId
                }
              />
            )}
          <PaymentPlanModal
            showModal={this.state.showPaymentPlanModal}
            setShowModal={this.setShowPaymentPlanModal}
            triggerUpdate={() => this.props.onRefresh()}
            installmentsMinAmount={this.props.installmentsMinAmount}
            practiceName={this.props.practiceName}
            practiceId={this.props.practiceId}
          />
          {this.props.paymentLinkEnabled ? (
            <div className='p-4'>
              <div className='flex-wrap pt-3 pb-4 d-flex align-items-baseline'>
                <h1 className='mr-2'>Summary</h1>
              </div>
              <div className='flex-wrap gap-2 d-flex'>
                {this.renderSummary()}
              </div>
            </div>
          ) : (
            ''
          )}
          <ItemsTable
            keyField='id'
            title={'Transactions'}
            columns={dailyTransactionColumns}
            noDataMessage={'No transactions to display'}
            data={this.props.transactions}
            sortedByMostRecent={true}
            paginationEnabled={false}
            refresher={true}
            isLoading={this.props.isLoading}
            onRefresh={() => this.props.onRefresh()}
          />
        </TokenPayProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentLinkEnabled: state.loggedPractice.paymentLinkEnabled,
  onlyVetshipRxPayments: state.loggedPractice.onlyVetshipRxPayments,
  installmentsEnabled: state.loggedPractice.installments,
  installmentsMinAmount: state.loggedPractice.installmentsMinAmount,
  paymentPlatform: state.loggedPractice.paymentPlatform,
  payInFullEnabled:
    state.loggedPractice.paymentPlatform === PaymentPlatforms.spreedly ||
    state.loggedPractice.paymentPlatform === PaymentPlatforms.payfabric ||
    state.loggedPractice.paymentPlatform === PaymentPlatforms.rectangle,
  payFabricDeviceIds: state.loggedPractice.payFabricDeviceIds,
  stripeId: state.loggedPractice.stripeId,
  practiceName: state.loggedPractice.name,
  practiceId: state.loggedPractice.id,
});

export default connect(mapStateToProps, {})(DailyTransactionsList);
