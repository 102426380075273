import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { parseNames, getFirstName, getLastName } from 'utils/helpers/names';
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import CustomerInfo from './CustomerInfo';
import Loading from 'components/Common/Loading';
import PaymentRectangle from '../../PaymentRectangle/PaymentRectangle';
import { createWallet, updateWallet } from 'utils/api/rectangleApi';
import {
  createNewCustomer,
  updateCustomer,
  updatePaymentMethod,
} from 'utils/api/customerApi';
import styles from './RectangleCustomerModal.module.scss';
import PropTypes from 'prop-types';

const CUSTOMER_INFO_STEP = 0;
const CARD_INFO_STEP = 1;

export const editModes = Object.freeze({
  NEW: 0,
  INFO: 1,
  CARD: 2,
});

const ERROR_SAVING_CUSTOMER =
  'There was a problem saving the customer, please try again';
const ERROR_ADDING_CARD =
  'There was a problem adding the card, please try again';

export function RectangleCustomerModal(props) {
  const {
    show,
    onHide,
    onAdd,
    onUpdate,
    editMode = editModes.NEW,
    customerToEdit = null,
    publicKey,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customerData: customerToEdit || {},
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [modalStep, setModalStep] = useState(CUSTOMER_INFO_STEP);
  const [newCustomerId, setNewCustomerId] = useState();
  const formRef = useRef(null);
  let paymentToken;

  useEffect(() => {
    if (show) {
      setModalStep(
        editMode === editModes.CARD ? CARD_INFO_STEP : CUSTOMER_INFO_STEP
      );
    }
  }, [show, editMode]);

  async function handleModalExited() {
    reset();
    setIsLoading(false);
    setModalStep(CUSTOMER_INFO_STEP);
  }

  async function handleBackwardClick() {
    if (modalStep === CUSTOMER_INFO_STEP || editMode === editModes.CARD) {
      onHide();
    } else {
      setModalStep(CUSTOMER_INFO_STEP);
    }
  }

  const onSubmit = async (formData) => {
    if (modalStep === CUSTOMER_INFO_STEP) {
      const [firstName, lastName] = parseNames(formData.customerData.full_name);
      if (editMode === editModes.INFO && customerToEdit) {
        setIsLoading(true);
        try {
          const updateRecord = {
            customerData: {
              ...formData.customerData,
              first_name: firstName,
              last_name: lastName,
            },
          };
          const updatedCustomer = await updateCustomer(
            updateRecord,
            customerToEdit.customer_id
          );
          onUpdate(updatedCustomer.data);
          onHide();
        } catch (e) {
          console.error(e);
          toast.error(ERROR_SAVING_CUSTOMER);
        } finally {
          setIsLoading(false);
        }
      } else {
        setModalStep(CARD_INFO_STEP);
        setNewCustomerId(uuid());
      }
      return;
    }

    if (editMode === editModes.NEW && modalStep === CARD_INFO_STEP) {
      try {
        if (!paymentToken) {
          throw new Error('Token not found');
        }

        setIsLoading(true);
        const fullName = formData.customerData.full_name;
        const walletResponse = await createWallet(
          fullName,
          paymentToken,
          newCustomerId
        );
        if (!walletResponse.walletId) throw new Error('Wallet Id Missing');

        const insertRecord = {
          customerData: {
            customer_id: newCustomerId,
            first_name: getFirstName(fullName),
            last_name: getLastName(fullName),
            phone: formData.customerData.phone,
          },
          paymentMethodData: {
            full_name: fullName,
            payment_method_token: walletResponse.walletId,
            fingerprint: '',
            card_type: '',
            last_four: walletResponse.lastFour,
            expiration_month: walletResponse.expirationDate.slice(0, 2),
            expiration_year: walletResponse.expirationDate.slice(-2),
          },
        };
        const newCustomer = await createNewCustomer(insertRecord);
        onAdd(newCustomer.data);
        onHide();
      } catch (e) {
        console.error(e);
        toast.error(ERROR_ADDING_CARD);
      } finally {
        setIsLoading(false);
      }
    } else if (editMode === editModes.CARD) {
      try {
        setIsLoading(true);
        const walletResponse = await updateWallet(
          customerToEdit.full_name,
          paymentToken,
          customerToEdit.customer_id
        );
        const paymentMethodData = {
          full_name: customerToEdit.full_name,
          payment_method_token: walletResponse.walletId,
          fingerprint: '',
          card_type: '',
          last_four: walletResponse.lastFour,
          expiration_month: walletResponse.expirationDate.slice(0, 2),
          expiration_year: walletResponse.expirationDate.slice(-2),
        };

        const newPaymentMethod = await updatePaymentMethod(
          paymentMethodData,
          customerToEdit.customer_id,
          customerToEdit.payment_methods[0].id
        );
        onUpdate({
          customer_id: customerToEdit.customer_id,
          ...newPaymentMethod.data,
        });
        onHide();
      } catch (e) {
        console.error(e);
        toast.error(ERROR_SAVING_CUSTOMER);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTokenAction = (token) => {
    paymentToken = token;
    handleSubmit(onSubmit)();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={handleModalExited}
      dialogClassName={`${styles.modalBody} ${
        modalStep === CARD_INFO_STEP && styles.payfabricModal
      }`}
    >
      <ModalHeader>
        <ModalTitle>{getModalTitle(editMode)}</ModalTitle>
      </ModalHeader>
      <ModalBody
        className={`${styles.modalBody} ${
          modalStep === CARD_INFO_STEP && styles.payfabricModalBody
        }`}
      >
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          {!isLoading && modalStep === CUSTOMER_INFO_STEP && (
            <CustomerInfo
              register={register}
              errors={errors}
              control={control}
              customerToEdit={customerToEdit}
            />
          )}
        </form>

        {!isLoading && modalStep === CARD_INFO_STEP && (
          <PaymentRectangle
            publicKey={publicKey}
            isSaveCard={true}
            amount={0}
            backAction={handleBackwardClick}
            completePaymentAction={handleTokenAction}
          />
        )}

        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
          >
            <Loading />
          </div>
        )}
      </ModalBody>
      {modalStep !== CARD_INFO_STEP && !isLoading && (
        <ModalFooter>
          <Button
            className={styles.button}
            variant='light'
            onClick={handleBackwardClick}
            disabled={isLoading}
          >
            {modalStep === CUSTOMER_INFO_STEP || editMode === editModes.CARD
              ? 'Cancel'
              : 'Back'}
          </Button>
          <Button
            className={styles.button}
            variant='primary'
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {editMode === editModes.NEW ? 'Next' : 'Save'}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

function getModalTitle(editMode) {
  switch (editMode) {
    case editModes.NEW:
      return 'Add New Customer';
    case editModes.INFO:
      return 'Edit Customer';
    case editModes.CARD:
      return 'Replace Saved Card';
    default:
      return '';
  }
}

export function CustomerModal(props) {
  const { show, onHide, onAction, editMode, customerToEdit, publicKey } = props;

  return (
    <RectangleCustomerModal
      show={show}
      onHide={onHide}
      onAdd={onAction}
      onUpdate={onAction}
      editMode={editMode}
      customerToEdit={customerToEdit}
      publicKey={publicKey}
    />
  );
}

RectangleCustomerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  editMode: PropTypes.oneOf([editModes.NEW, editModes.INFO, editModes.CARD]),
  customerToEdit: PropTypes.object,
  publicKey: PropTypes.string.isRequired,
};
