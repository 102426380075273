import { Layout, Loader, PracticeBanner } from '@vitusvet/react-library';
import React, { useEffect, useState } from 'react';
// Styles
import styles from '../PaymentWidget/PaymentWidget.module.scss';
import queryString from 'query-string';
import Env from 'Env';
import { createPayDirect } from 'utils/api/payfabricApi';
import { getPracticeByIDPublic } from 'utils/api/practiceApi';
import PhoneInput from 'react-phone-number-input/input';
import { useLocation } from 'react-router-dom';

function PaymentWidget() {
  const [loaded, setLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    amount: 0,
    invoiceId: '',
  });
  const [practice, setPractice] = useState(null);
  let location = useLocation();

  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      setIsSubmitting(true);
      console.log(formData);
      const amountCents = formData.amount * 100;

      const postBody = {
        practiceId: practice.id,
        petOwnerName: `${formData.firstName} ${formData.lastName}`,
        petOwnerPhone: phoneNumber,
        invoiceId: formData.invoiceId,
        amount: amountCents,
      };
      const response = await createPayDirect(postBody);

      const spreedlyReferenceData = JSON.parse(
        response.Attributes.spreedlyReferenceData
      );

      window.location = spreedlyReferenceData.paymentLink;
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      console.log('Form is invalid');
      form.reportValidity();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    try {
      const parsed = queryString.parse(location.search);
      getPracticeByIDPublic(parsed.pid).then((practice) => {
        setLoaded(true);
        setPractice({
          id: parsed.pid,
          coreId: practice.coreId,
          name: practice.name,
          coreData: {},
          phone: practice.phone1,
        });
      });
    } catch (err) {
      console.log(err.message);
      setLoaded(true);
    }
  }, [setPractice, location]);

  const date = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const formattedDate = formatter.format(date);

  if (!loaded) return <div className={styles.container}></div>;
  if (practice == null) {
    return (
      <div className={styles.container}>
        <Layout>
          <p className={styles.header}>
            {' '}
            <span className={styles.headerText}>Error</span>
          </p>
          <p>Requested Page Not Found</p>
        </Layout>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Layout>
        <p className={styles.header}>
          <span className={styles.headerText}>Payment Form</span>
          <span className={styles.headerDate}>{formattedDate}</span>
        </p>
        {practice.coreId && (
          <PracticeBanner
            imgUrl={`${Env.coreUrl}/images/clients/${practice.coreId}/logo.png`}
            name={practice?.name}
            street={
              practice?.coreData?.address1
                ? `${practice?.coreData?.address1}${
                    practice?.coreData?.address2
                      ? `, ${practice?.coreData?.address2}`
                      : ''
                  }`
                : ''
            }
            city={practice?.coreData?.city ?? ''}
            state={practice?.coreData?.state ?? ''}
            zip={practice?.coreData?.zip ?? ''}
            phone={practice?.phone ? practice?.phone.toString() : ''}
          />
        )}
        <form onSubmit={handleSubmit}>
          <p>
            <div className={styles.formGroup}>
              <div>
                <label htmlFor='firstName'>Client Name</label>
                <input
                  className='form-control'
                  type='text'
                  id='firstName'
                  name='firstName'
                  placeholder='First Name'
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor='lastName'>&nbsp;</label>
                <input
                  className='form-control'
                  type='text'
                  id='lastName'
                  name='lastName'
                  placeholder='Last Name'
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <label htmlFor='phoneNumber'>Phone Number</label>
            <PhoneInput
              name='phoneNumber'
              type='text'
              country='US'
              placeholder='(xxx) xxx-xxxx'
              className='form-control'
              onChange={setPhoneNumber}
              required
              value={phoneNumber}
              pattern='\([0-9]{3}\) [0-9]{3}-[0-9]{4}'
            ></PhoneInput>

            <label htmlFor='invoiceId'>Description</label>
            <input
              className='form-control'
              type='text'
              name='invoiceId'
              required
              placeholder='Invoice Number or Pet Name'
              onChange={handleChange}
            />
            <div className='form-group'>
              <label htmlFor='amount'>Payment Amount</label>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    <i className='fas fa-dollar-sign'></i>
                  </span>
                </div>

                <input
                  className={styles.amount + ' form-control'}
                  type='number'
                  name='amount'
                  onChange={handleChange}
                  placeholder='0.00'
                  required
                  min='1'
                  max='9999999'
                  step='.01'
                />
              </div>
            </div>
          </p>

          <button className='btn btn-secondary'>Next</button>
          {isSubmitting && <Loader></Loader>}
        </form>
      </Layout>
    </div>
  );
}

export default PaymentWidget;
