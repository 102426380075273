import axios from 'axios';
import Auth from './../auth';
import LogRocket from 'logrocket';
import { toast } from 'react-toastify';
import Env from 'Env';
import * as Sentry from '@sentry/react';

const utils = {
  sessionExpiredAlertFired: null,
};

export const API_AUTH_URL = Env.endpoints.auth;
export const API_USER_URL = Env.endpoints.user;
export const API_PRACTICE_URL = Env.endpoints.practice;
export const API_TRANSACTION_URL = Env.endpoints.transaction;
export const API_SPREEDLY_URL = Env.endpoints.spreedly;
export const API_MERCHANT_URL = Env.endpoints.merchant;
export const API_INSTALLMENTS_URL = Env.endpoints.installments;
export const API_CUSTOMERS_URL = Env.endpoints.customers;
export const API_PAYFABRIC_URL = Env.endpoints.payfabric;
export const API_RECTANGLE_URL = Env.endpoints.rectangle;

/**
 * Used for http requests that require authorization
 * PRACTICE API ENDPOINT
 */
export const axiosAuthInstance = axios.create({
  baseURL: API_AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosUserInstance = axios.create({
  baseURL: API_USER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosPracticeInstance = axios.create({
  baseURL: API_PRACTICE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosTransactionInstance = axios.create({
  baseURL: API_TRANSACTION_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosTransactionPublicInstance = axios.create({
  baseURL: API_TRANSACTION_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosMerchantInstance = axios.create({
  baseURL: API_MERCHANT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosSpreedlyInstance = axios.create({
  baseURL: API_SPREEDLY_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosSpreedlyPublicInstance = axios.create({
  baseURL: API_SPREEDLY_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosInstallmentsInstance = axios.create({
  baseURL: API_INSTALLMENTS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosInstallmentsPublicInstance = axios.create({
  baseURL: API_INSTALLMENTS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosCustomersInstance = axios.create({
  baseURL: API_CUSTOMERS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosPayFabricInstance = axios.create({
  baseURL: API_PAYFABRIC_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosPayFabricPublicInstance = axios.create({
  baseURL: API_PAYFABRIC_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosRectangleInstance = axios.create({
  baseURL: API_RECTANGLE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * HTTP Request Interceptor
 * @param {object} config
 */
const attachCredentials = async (config) => {
  const auth = new Auth();
  const {
    isAuthenticated,
    renewSession,
    handleAuthentication,
    storeValues,
    logout,
  } = auth;

  if (isAuthenticated(true)) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      storeValues.ID_TOKEN
    )}`;
    utils.sessionExpiredAlertFired = false;
    return config;
  } else {
    try {
      const RefreshToken = localStorage.getItem(storeValues.REFRESH_TOKEN);
      if (!RefreshToken) {
        throw new Error('Refresh token not found');
      }
      const result = await renewSession();

      const installmentsConfig = localStorage.getItem(
        storeValues.INSTALLMENTS_CONFIG
      )
        ? JSON.parse(localStorage.getItem(storeValues.INSTALLMENTS_CONFIG))
        : {};
      const payload = {};

      payload.AuthenticationResult = {
        RefreshToken,
        ...result.AuthenticationResult,
      };
      payload.installments_min_amount =
        installmentsConfig.installments_min_amount || 0;
      payload.globalInstallmentsPlan = installmentsConfig.globalInstallmentsPlan
        ? installmentsConfig.globalInstallmentsPlan
        : [];

      handleAuthentication(payload);
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        storeValues.ID_TOKEN
      )}`;
      utils.sessionExpiredAlertFired = false;

      return config;
    } catch (err) {
      await logout();
      if (!utils.sessionExpiredAlertFired) {
        toast.warn('Your session has expired.');
      }
      utils.sessionIsExpired = true;
      window.location.reload();
      return Promise.reject(new Error('User logged out'));
    }
  }
};

/**
 * HTTP Request interceptor Middleware
 */
axiosMerchantInstance.interceptors.request.use(attachCredentials);
axiosUserInstance.interceptors.request.use(attachCredentials);
axiosPracticeInstance.interceptors.request.use(attachCredentials);
axiosTransactionInstance.interceptors.request.use(attachCredentials);
axiosSpreedlyInstance.interceptors.request.use(attachCredentials);
axiosInstallmentsInstance.interceptors.request.use(attachCredentials);
axiosCustomersInstance.interceptors.request.use(attachCredentials);
axiosPayFabricInstance.interceptors.request.use(attachCredentials);
axiosRectangleInstance.interceptors.request.use(attachCredentials);

/**
 * Generic Success response handler
 * @param {object} response
 */
export async function handleResponse(response) {
  if (response.status === 200) {
    let res = response.data ? response.data.data : response;
    if (!res) {
      res = response;
    }
    return res;
  }
  return response;
}

/**
 * Generic Error Response handler
 * @param {Error} error
 */
export function handleError(error) {
  try {
    let status = error.request ? error.request.status : false;
    let err = {};
    if (error.message === 'User logged out') {
      /* 
        Axios interceptor will logout the user if they do not have a valid refresh token.
        This is expected behavior and should not be logged or rethrown.
      */
      return;
    }
    if (Env.logRocketEnabled) {
      LogRocket.captureException(error);
    }
    if (Env.sentryEnabled && error?.response?.status === 500) {
      Sentry.withScope((scope) => {
        scope.setExtra('hideReportError', true);
        let name = 'Server Error';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          name += ': ' + error.response.data.message;
        }
        error.name = name;
        Sentry.captureException(error);
      });
    }
    switch (status) {
      case 500:
        error.message = 'There is a problem with the server at the moment';
        error.status = status;
        throw error;
      case 400:
        err = error.response ? error.response.data : error;
        if (err) {
          if (err.error) {
            err.message = err.error.message;
          } else {
            err.message = err.userMessage ? err.userMessage : err.message;
          }
        } else {
          err = error;
        }
        err.status = status;
        throw err;
      case 401:
        err.message = 'You do not have permission to perform this operation';
        err.status = status;
        throw err;
      default:
        error.status = status;
        throw error;
    }
  } catch (err) {
    console.log("error handling error... wow that's meta");
    throw err;
  }
}

export const iterateLastEvaluatedKey = (getter, maxCount, pageCallback) => {
  const getResultPage = async (lastEvaluatedKey = null, count = 0) => {
    return getter(lastEvaluatedKey).then((response) => {
      if (pageCallback) {
        pageCallback(response);
      }
      if (response.LastEvaluatedKey && count < maxCount) {
        return getResultPage(response.LastEvaluatedKey, count + 1);
      }
    });
  };
  return getResultPage();
};
